@import "../../styles/constants/colors";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .filterBar {
    display: none;
    margin: 0 -0.4rem;

    form {
      margin-bottom: 0;
    }
  }

  .searchingWrapper {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    gap: 8px;

    .searchBar {
      label {
        margin: 0;
      }

      input {
        border-color: #002147;
      }

      input::placeholder {
        color: #002147;
      }

      button {
        border-color: #002147;
      }
    }
  }
}

.materials h2 {
  width: 90%;
  margin: 0 auto;
  margin-top: 32px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
}

.item {
  display: flex;
  overflow: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  margin: 0 auto;
}

.selectAll {
  display: flex;
  overflow: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  margin: 0 auto;

  label {
    margin-top: 0 !important;
  }

  button {
    svg {
      position: relative;
      top: 0;
    }

    span {
      position: relative;
      top: 1px;
    }
  }
}

.item::after {
  display: table;
  clear: both;
  content: "";
}

.cover {
  width: 70px;

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
}

.info {
  display: flex;
  max-width: 445px;
  align-items: center;
}

.title {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

.description {
  font-size: 16px;
  line-height: 24px;
}

.actions {
  margin-left: auto;
  font-size: 14px;
  text-align: center;

  svg {
    position: relative;
    top: 2px;
    width: 20px;
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    gap: 8px;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.hr {
  margin: 16px 0;
}

.unitDataWrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.noDownloads {
  margin-top: 20px;
  text-align: center;

  p {
    display: none;
  }
}

.information {
  > div {
    max-width: 100%;
    padding-bottom: 0;

    > div {
      margin-bottom: 0 !important;
    }

    a {
      text-decoration: underline;
    }

    p {
      font-size: 0.9rem;
    }
  }
}
