@use "sass:map";
@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/variables";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.OcpHomeLowerRow {
  position: relative;
  margin: 32px 24px 16px;
  font-family: $font-family_text_font;

  @media #{$xsMedia} {
    margin: 24px 16px 16px;
  }

  .OcpHomeLowerRowContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .InformationBox {
      width: 90%;
      margin-bottom: 40px;
    }

    .TermsContainer {
      display: table;
      width: 90%;
      max-width: 577px;
      margin: 40px 0 80px;
      text-align: center;

      @media #{$xsMedia} {
        margin-bottom: 60px;
      }

      p {
        display: table-cell;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: -0.017em;
        line-height: 150%;
        vertical-align: middle;
      }
    }

    .ManualInputContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      p {
        margin-top: 40px;
        font-size: 21px;
        font-weight: 700;
      }

      button {
        margin-top: 16px;
      }

      @media #{$xsMedia} {
        p {
          margin-top: 24px;
        }
      }
    }
  }
}
