@use "sass:map";
@import "../../../styles/constants/colors";

.container {
  width: 100%;
  padding: 5px 0 0;
  margin: 0 auto;
}

.container h1 {
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid $color-light-grey-border;
  margin-bottom: 0;
  color: map.get($cptColors, dark_blue);
  font-size: 21px;
  line-height: 30px;
}
