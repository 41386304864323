@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";

.unitsContainer {
  margin: 0 auto;

  .unitItem {
    display: flex;
    padding: $gap_2 $gap_4;
    gap: $gap-2;

    .unitNameColumn {
      display: flex;
      flex: 1;
      align-items: center;

      .unitName {
        margin: 0;
        margin-left: $gap-4;
        color: #002147;
        font-family: "Open Sans", sans-serif;
        font-size: 0.95rem;
        font-weight: 700;
      }
    }

    .sizeColumn {
      display: flex;
      flex-basis: auto;

      .size {
        align-self: center;
        padding: 0.125rem 0.5rem;
        border-radius: 5px;
        background: #EAEFEF;
        font-size: 12px;
      }
    }

    .actionsColumn {
      display: flex;
      flex-basis: auto;
      margin-left: $gap-4;
    }
  }

  .unitWrapper {
    border-bottom: 1px solid #CCD3DA;

    .printViewCheckboxContainer {
      padding: 0 0 $gap-3 $gap-4;
      margin-top: $gap-1;
    }

    .printViewCheckboxSubContainer {
      display: inline-block;
      flex-direction: row;
      padding: $gap-3 $gap-4 $gap-3 $gap-4;
      border-radius: 10px;
      margin-left: $gap-4;
      color: map.get($primary, primary100);
      gap: 8px;
    }
  }
}
