.container {
  width: 100%;
  height: 100%;

  iframe[title] {
    position: fixed;
    top: 1px;
    display: block;
    width: 1px;
    height: 1px;
  }
}
