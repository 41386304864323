@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";
@import "../../../styles/constants/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.item {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 95%;
  max-width: 842px;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  margin: 0 auto;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.topRow {
  display: flex;
  width: 95%;
  align-items: center;
}

.item:hover {
  background-color: #e0e0e0;
  transform: scale(1.02);
}

.selected {
  border: 2px solid #e0e0e0;
  background-color: #d3d3d3;
}

.item::after {
  display: table;
  clear: both;
  content: "";
}

.nameWithThumbnail {
  display: flex;
}

.nameContainer {
  display: flex;
  max-width: 250px;
  align-self: center;
  text-overflow: ellipsis;
}

.name {
  margin-left: 5px;
  font-size: 16px;
  line-height: 24px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: $gap-4;
  margin-left: auto;
  font-size: 14px;
  gap: $gap-4;
  text-align: center;
}

.actions svg {
  position: relative;
  width: 20px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteButtonText {
  margin-left: 10px;

  @media screen and (max-width: $screen-width-840) {
    display: none;
  }
}

.userDetailsThumbnail {
  margin-right: 16px;

  > div {
    margin-right: 0;
    background-color: #f3a7b8;
  }

  + div {
    overflow-x: hidden;
  }

  span {
    color: #333;
  }
}

.userDelimiterContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.userDelimiter {
  z-index: 1000;
  width: 95%;
  max-width: 842px;
  box-sizing: border-box;
  border: none;
  border-top: 1px solid map.get($primary, primary20);
  margin: 15px auto;
}

.chooseUserTitle {
  position: relative;
  left: 50%;
  max-width: 842px;
  margin: $gap-6 0 $gap-3;
  font-family: $font-family-display-font;
  font-size: $font-size-3xlarge;
  font-weight: $font-weight-400;
  line-height: $line-height-110;
  transform: translateX(-50%);

  @media screen and (max-width: $screen-width-840) {
    margin-left: 10px;
    font-size: $font-size-2xlarge;
  }
}

.noOfflineProfilesText {
  margin-top: 20px;
  text-align: center;
}

.nextButton {
  display: flex;
  width: $icon-size-larger;
  height: $icon-size-large;
  max-height: $icon-size-medium;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid map.get($cptColors, light_blue2);
  border-radius: 4px;
  background-color: map.get($primary, primary100);
  fill: map.get($cptColors, white);
  line-height: 2.9rem;

  svg {
    width: $icon-size-small;
    height: $icon-size-small;
  }

  &:hover {
    background-color: map.get($primary, primary90);
  }

  &:focus {
    box-shadow: $box-shadow-focus;
  }
}

.deleteButtonContainer {
  display: flex;
  margin-right: 10px;
}
