@use "sass:map";
@import "../../styles/constants/colors";
@import '../../styles/constants/breakpoints';

$header-height: 75px;
$footer-height: 50px;

.ocpApp {
  /* background: map-get($background-and-border-colors, selected); */

  .pageContainer {
    display: flex;
    min-height: calc(var(--vh100, 100vh) - #{$header-height});
    flex-direction: column;

    main div.srOnly {
      position: absolute;
      overflow: hidden;
      width: 1px;
      height: 1px;
      margin: 0;
      opacity: 0;
      pointer-events: none;

      h1 {
        color: map.get($legacy-color-names, tooltip);
        font-size: 1rem;
        line-height: 1rem;
      }
    }

    footer {
      margin-top: auto;
    }

    .loadingSpinnerWrapper {
      display: flex;
      height: calc(var(--vh100, 100vh) - #{$header-height} - #{$footer-height});
      min-height: calc(var(--vh100, 100vh) - #{$header-height} - #{$footer-height});
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
