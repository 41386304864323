$phoneBreakpoint: 560px;
$smBreakpoint: 768px;
$mdBreakpoint: 972px;
$smMdBreakpoint: 991px;
$lgBreakpoint: 1180px;
$viewportWidth: 100vw;

$xsMedia: "screen and (max-width: #{$smBreakpoint - 1px})";
$smMedia: "screen and (min-width: #{$smBreakpoint}), print";
$phoneMedia: "screen and (max-width: #{$phoneBreakpoint}), print";
$smTabletMedia: "screen and (max-width: #{$smBreakpoint})";
$tabletMedia: "screen and (max-width: #{$mdBreakpoint})";
$mdMedia: "screen and (min-width: #{$mdBreakpoint})";
$smMdMedia: "screen and (min-width: #{$smMdBreakpoint})";
$lgMedia: "screen and (min-width: #{$lgBreakpoint})";
$lgMediaMax: "screen and (max-width: #{$lgBreakpoint})";
$ipadAirMedia: "screen and (min-device-width: #{$smBreakpoint}) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)";

:export {
  /* stylelint-disable property-case */
  ipadAirMedia: $ipadAirMedia;
  lgBreakpoint: $lgBreakpoint;
  lgMedia: $lgMedia;
  mdBreakpoint: $mdBreakpoint;
  mdMedia: $mdMedia;
  phoneBreakpoint: $phoneBreakpoint;
  phoneMedia: $phoneMedia;
  smBreakpoint: $smBreakpoint;
  smMdBreakpoint: $smMdBreakpoint;
  smMdMedia: $smMdMedia;
  smMedia: $smMedia;
  smTabletMedia: $smTabletMedia;
  /* stylelint-enable */
}

// Smart
$smart-desktop:  "screen and (min-width: 1024px)";
$smart-mobile:  "screen and (max-width: 1023px)";
